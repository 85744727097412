import React from "react"
import { graphql } from "gatsby"

import IndexLayout from '../layouts'
import Carrousel from "../components/Carrousel"
import ReactMarkdown from "react-markdown"
import gfm from "remark-gfm"

const IndexPage = ({ data }) => {
  return (
    <IndexLayout>
      <Carrousel images={data.allStrapiHome.nodes[0].Carrousel.map(photo => {return photo.localFile})}></Carrousel>
      <div className="hero is-primary">
        <div className="hero-body">
          <div className="container ">
            <h1 className="title has-text-centered">{data.allStrapiHome.nodes[0].Titre}</h1>
          </div>
        </div>
      </div>
      <div className="section ">
        <ReactMarkdown className="container content" plugins={[gfm]} children={data.allStrapiHome.nodes[0].Information} />
      </div>
    </IndexLayout>
  )
}

export default IndexPage

export const pageQuery = graphql`
query HomeQuery {
  allStrapiHome {
    nodes {
      Titre
      SousTitre
      Information
      Carrousel {
        localFile {
          publicURL
        }
      }
    }
  }
}
`
